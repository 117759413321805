import React, {useEffect, useMemo, useState} from 'react';
import {Avatar, Input, Radio, Tag} from 'antd';
import {createAcronym} from '../Charters/CharterItinerary';
import {find, memoize, values} from 'lodash';
import {toHrsMinsSecs} from '../../libs/formatLib';
import LoadMessage from '../../components/LoadMessage';
import {DEFAULT_MAX_DRIVER_HRS} from '../../model/hrm/employee';

export const employeeName = memoize(employee => {
    return employee ? `${employee.firstName} ${employee.lastName}` : '?';
});
export const EmployeeAvatar = ({employee, statusColour = '#C1C5CE'}) => {
    return <Avatar key={`employee-avatar-${employee?.employeeID}`}
                   style={{backgroundColor: statusColour, verticalAlign: 'middle'}}
                   size={40}
                   src={employee?.avatarUrl}>{createAcronym(employeeName(employee))}</Avatar>;
};

const SelectEmployeeView = ({
                                scenario,
                                roster,
                                isDefault = false,
                                selectedEmployee, setSelectedEmployee,
                                employeeData,
                                onAvailabilityChange,
                                workItem
                            }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [availableStaff, setAvailableStaff] = useState([]);
    const [unavailableStaff, setUnavailableStaff] = useState([]);

    useEffect(() => {
        if (!scenario || !roster || !employeeData) return;
        let availableStaff = selectedEmployee ? [selectedEmployee] : [], unavailableStaff = [];
        values(employeeData.allEmployees).filter(e => !selectedEmployee || e.employeeID !== selectedEmployee.employeeID).forEach(employee => {
            const existingRoster = find(scenario.weeklyRosters, {defaultEmployeeId: employee.employeeID})
            if (isDefault && existingRoster && existingRoster.rosterId !== roster.rosterId) {
                unavailableStaff.push({
                    ...employee,
                    unavailableStatus: 'Already allocated'
                });
                return;
            }
            //     // } else if (job && scenario.isDriverAvailable(employee.employeeID, job)) {
            //     //         unavailableStaff.push({
            //     // ...employee,
            //     //             status: 'Already allocated'
            //     //         });
            // } else
            if ((scenario.getTotalTime('employee', employee.employeeID) / 3600) >= DEFAULT_MAX_DRIVER_HRS) {
                unavailableStaff.push({
                    ...employee,
                    unavailableStatus: 'Overtime'
                });
            } else if (scenario.isEmployeeUnavailable(employee.employeeID, workItem)) {
                unavailableStaff.push({
                    ...employee,
                    unavailableStatus: 'Unavailable'
                });
            } else {
                availableStaff.push({
                    ...employee,
                    hours: toHrsMinsSecs(scenario.getTotalTime('employee', employee.employeeID))
                });
            }
        });

        setAvailableStaff(availableStaff);
        setUnavailableStaff(unavailableStaff);
    }, [scenario, roster, isDefault, employeeData, setSelectedEmployee, setAvailableStaff, setUnavailableStaff, selectedEmployee]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredStaff = useMemo(() => {
        if (!searchTerm) return availableStaff;
        const search = searchTerm.toLowerCase();
        return availableStaff?.filter(employee => {
            return employee.name?.toLowerCase().includes(search) ||
                (search === 'driver' && employee.driverId);
        }) || [];
    }, [availableStaff, searchTerm]);

    useEffect(() => {
        // Check availability and pass true/false to parent
        onAvailabilityChange(unavailableStaff.length > 0);
    }, [unavailableStaff.length, onAvailabilityChange]);

    return (
        <>{employeeData ? <>
                <div className={`item-allocation ${unavailableStaff.length ? 'showStaffUnavailable' : 'hideStaffUnavailable'}`}>
                    <Input type="text" placeholder="Search staff" value={searchTerm} onChange={handleSearch}/>
                    <div className="item-list-scroll">
                        <div className="item-list">
                            {filteredStaff?.length ? filteredStaff.map((employee) => (
                                <div key={`available-staff-${employee.employeeID}`} className="item-single" onClick={() => setSelectedEmployee(employee)}>
                                    <Radio name="staff"
                                           checked={selectedEmployee?.employeeID === employee.employeeID}>
                                        <div className="d-flex align-items-center" style={{gap: '8px'}}>
                                            <EmployeeAvatar employee={employee}/>
                                            <span className="staff-name">{employeeName(employee)}</span>
                                        </div>
                                        <span className="staff-time">{employee.hours}</span>
                                    </Radio>
                                </div>
                            )) : <div className="no-results">No employees found</div>}
                        </div>
                    </div>
                    {unavailableStaff.length ? <><h6 className="mt-4 mb-0"
                                                     style={{color: '#777', fontWeight: 600}}>Unavailable Staff</h6>
                        <div className="item-list-scroll">
                            <div className="item-list">
                                {unavailableStaff.map((employee) => (
                                    <div key={`unavailable-staff-${employee.employeeID}`} className="item-unavailable">
                                        <div className="d-flex align-items-center" style={{gap: '8px'}}>
                                            <EmployeeAvatar employee={employee}/>
                                            <span className="staff-name">{employeeName(employee)}</span>
                                        </div>
                                        <span className="staff-status simple-tags"><Tag
                                            color="success">{employee.unavailableStatus}</Tag></span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </> : <></>}
                </div>
            </> :
            <LoadMessage title="Loading staff..."/>
        }
        </>
    );
};

export default React.memo(SelectEmployeeView);