import {getBounds, getViewport} from './mapLib';
import {filterVisibleStops} from './timetableLib';
import {renderToStaticMarkup} from 'react-dom/server';
import Pin from '../components/Pin';
import RouteTitle from '../components/RouteTitle';
import React from 'react';

export const renderSuggestions = (feature) => {
    if (!feature) {
        return null;
    }
    let placeName = feature.place_name.split(',');
    if (feature.place_type.indexOf('stop') > -1) {
        placeName[0] = renderToStaticMarkup(<Pin size={20}
                                                 type={feature.properties.stop.stopType}/>) + placeName[0];
    } else if (feature.place_type.indexOf('route') > -1) {
        placeName[0] = renderToStaticMarkup(<RouteTitle route={feature.properties.route} size="sm"/>);
    }

    return '<div class="mapboxgl-ctrl-geocoder--suggestion"><div class="mapboxgl-ctrl-geocoder--suggestion-title">' + placeName[0] + '</div><div class="mapboxgl-ctrl-geocoder--suggestion-address">' + placeName.splice(1, placeName.length).join(',') + '</div></div>';
};

const stopNameReplaceRegexp = /[\\.,\\-_'" ]/g;
export const localGeocoder = ({query, allStops, routes = [], filteredRoutes, verifiedOnly = true}) => {
    const cleanedQuery = query.toLowerCase().replace(stopNameReplaceRegexp, '');
    const filteredStopFeatures = filterVisibleStops(allStops, filteredRoutes, verifiedOnly).filter(s => s.stopName && s.stopName.toLowerCase().replace(stopNameReplaceRegexp, '').indexOf(cleanedQuery) > -1).map(s => {
        s.unsetLinkedStops();
        s.duplicates = null;
        return {
            type: 'Feature',
            id: `stop.${s.stopId}`,
            text: s.stopName,
            place_name: `${s.stopName}`,
            place_type: ['stop'],
            center: [s.lon, s.lat],
            properties: {
                stop: s
            }
        };
    }).sort((f1, f2) => f1.properties.stop.stopType === 'school' ? -1 : 1);
    const routeFilter = s => {
        return s.published && ((s.routeNumber && s.routeNumber.toLowerCase().indexOf(cleanedQuery) > -1) ||
            (s.routeName && s.routeName.toLowerCase().indexOf(cleanedQuery) > -1) ||
            (s.routeDetails && s.routeDetails.toLowerCase().indexOf(cleanedQuery) > -1));
    };
    const filteredRouteFeatures = routes.filter(routeFilter).map(r => {
        const vp = getViewport({geos: r});
        const bounds = getBounds(r);
        const bbox = [bounds[0][0], bounds[0][1], bounds[1][0], bounds[1][1]];
        return {
            type: 'Feature',
            id: `route.${r.routeId}`,
            text: r.routeNumber,
            place_name: `${r.routeNumber}`,
            place_type: ['route'],
            bbox: bbox,
            center: [vp.longitude, vp.latitude],
            properties: {
                route: r
            }
        };
    });
    if (filteredStopFeatures.length || filteredRouteFeatures.length) {
        return (filteredStopFeatures || []).concat(filteredRouteFeatures || []);
    }
    if(query.split(',').length === 2) {
        const [lat, lon] = query.split(',').map(parseFloat);
        if (isNaN(lat) || isNaN(lon) || lat < -90 || lat > 90 || lon < -180 || lon > 180) return [];
        return [{
            type: 'Feature',
            id: `point.${query}`,
            text: query,
            place_name: query,
            place_type: ['point'],
            center: [lon, lat],
            properties: {
                point: {lat, lon}
            }
        }];
    }
};

