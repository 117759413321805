import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {onError} from '../libs/errorLib';
import './Timetable.css';
import RouteMapViewer from '../components/RouteMapViewer';
import LoadMessage from '../components/LoadMessage';
import {union, values} from 'lodash';
import {
    routeModelData,
    routesModelExpiryService,
    scheduleModelData,
    stopModelData,
    transferModelData
} from '../services/ModelService';
import {getFilterFromParams} from '../libs/timetableLib';
import {getKey} from '../libs/hooksLib';
import {Button, DatePicker, Dropdown, Radio, Tabs} from 'antd';
import {getArrivalTimeAsSecondsSinceMidnight, getDepartureTimeAsSecondsSinceMidnight} from '../libs/routes-lib';
import {flatten, uniqBy} from 'lodash/array';
import ReactGA from 'react-ga4';
import {find, keyBy} from 'lodash/collection';
import {FormControl, InputGroup} from 'react-bootstrap';
import {SearchOutlined} from '@ant-design/icons';
import {ReactComponent as Refine} from '../assets/icons/Refine.svg';
import dayjs from '../dayjs';
import {useDebounce} from 'rooks';
import {API, Auth} from 'aws-amplify';
import TimetableRouteList from '../components/TimetableRouteList';
import useAllowComments from '../hooks/useAllowComments';
import {DATE_STRING, Schedule} from '../model/schedule';
import {BusRoute, Stop} from '../model/busRoute';
import {Transfer} from '../model/transfer';
import {useAppContext} from '../libs/contextLib';
import {getPubOperator} from '../services/routeService';

const {TabPane} = Tabs;

export const getAvailableDirections = (DIRECTIONS, filter) => {
    return DIRECTIONS.filter(d => (filter.school && d.service === 'school') || (filter.regular && d.service === 'regular'));
};

const schoolDirectionOptions = [
    {label: 'AM', value: 'AM', disabled: false},
    {label: 'PM', value: 'PM', disabled: false},
];

const regDirectionOptions = [
    {label: 'Inbound', value: 'Inbound', disabled: false},
    {label: 'Outbound', value: 'Outbound', disabled: false},
];

const items = [
    {
        key: '1',
        label: (
            <a target="_blank" rel="noopener noreferrer" href=""></a>
        ),
    }
];

function Timetables({operatorId, setChatOpen, setChatContext}) {
    const {user} = useAppContext();
    const [routes, setRoutes] = useState(null);
    const [schedules, setSchedules] = useState(null);
    const [selectedSchedule, setSelectedSchedule] = useState({});
    const [allStops, setAllStops] = useState(null);
    const [allTransfers, setAllTransfers] = useState(null);
    const [filteredRoutes, setFilteredRoutes] = useState([]);
    const [filter, setFilter] = useState({school: true, regular: true, directions: [], immutable: []});
    const [startup, setStartup] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [selectedRouteIds, setSelectedRouteIds] = useState([]);
    const [highlightedRouteIds, setHighlightedRouteIds] = useState([]);
    const [zoom, setZoom] = useState([]);
    const [selectedStop, setSelectedStop] = useState(null);
    const [focusStop, setFocusStop] = useState(null);
    const [operator, setOperator] = useState(null);
    const [focusTransfers, setFocusTransfers] = useState({to: [], from: []});
    const [focusPt, setFocusPt] = useState(null);
    const [allowComments, setAllowComments] = useAllowComments();
    const [showComments, setShowComments] = useState(false);

    // eslint-disable-next-line
    const [title, setTitle] = useState('');
    const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);
    const routeNumberSortFn = (r1, r2) => r1.routeNumber.localeCompare(r2.routeNumber);

    useEffect(() => {
        async function onLoad() {
            try {
                const key = operatorId || await getKey();

                if (!user && searchParams.get('s')) {
                    try {
                        console.log(searchParams.toString());
                        let {
                            routes,
                            schedules,
                            stops,
                            transfers
                        } = await API.get('public', `/timetables/stops?_k=${key}&${searchParams.toString()}`,
                            {headers: {'x-accept-encoding': 'gzip'}});
                        // stops = stops.map(s => {
                        //     s = new Stop(s);
                        //     return s;
                        // })
                        stops = keyBy(stops, 'stopId')
                        routes = routes.map(r => {
                            r = new BusRoute(r)
                            r.setBaseStops(stops)
                            return r;
                        });
                        setFilteredRoutes(routes);
                        setRoutes(routes);
                        schedules = schedules.map(r => new Schedule(r));
                        setSchedules(keyBy(schedules, 'scheduleId'));
                        setAllStops(stops);
                        transfers = transfers.map(r => new Transfer(r));
                        setAllTransfers(transfers);
                        console.log('Getting timetable data for %s with %s', key, window.location.search);
                        return;
                    } catch (e) {
                        onError(e);
                    }
                }

                // let time = Date.now()
                // await API.get('public', `/jp/startup?_k=${key}&routes=true`,
                //     {headers: {'x-accept-encoding': 'gzip'}}).then(({stops: basicStops, schedules, operator, routes}) => {
                //
                //     basicStops = basicStops.map(s => {
                //         s.verified = 1
                //         return new Stop(s)
                //     })
                //     basicStops = keyBy(basicStops, 'stopId')
                //     setAllStops(basicStops);
                //     schedules = keyBy(schedules.map(s => new Schedule(s)), 'scheduleId')
                //     setSchedules(schedules);
                //     operator.features = new Features(operator.features);
                //     setOperator(operator);
                //     routes = routes.map(r => {
                //         r = new BusRoute(r)
                //         r.published = 1;
                //         return r;
                //     }).sort(routeNumberSortFn)
                //     setRoutes(routes);
                //     // setFilteredRoutes(routes)
                //     setStartup(false)
                //
                //     console.log('Took', Date.now() - time, 'ms to startup')
                // });


                await routesModelExpiryService.init({
                    apiKey: key,
                    modelFilter: ['Stop', 'Transfer', 'Route', 'Schedule', 'RouteComment', 'StopComment']
                });

                const operator = await getPubOperator(key);
                setOperator(operator);

                // await operatorModelData.addListener({loaded: operators => setOperator(operators[key])})

                // routeModelData.setFetchOption('includeStopTimes', true)
                //     .setFetchOption('includeStops', true)
                //     .setFetchOption('includePath', true)
                //     .setFetchOption('publishedOnly', true)
                //     .setFetchOption('loadSchedules', true)
                //     .setFetchOption('shrinkRate', 0.00001);

                // Add filter to fetch options

                // const stopsById = await stopModelData.getAll();
                // setAllStops(stopsById);
                stopModelData.addListener({
                    id: 'TimetablesStopListener',
                    setterFn: setAllStops,
                    loaded: stopsById => {
                        setAllStops(stopsById);
                    }
                });

                // Object.keys(filter).filter(fKey => {
                //     return Object.keys(filter.immutable).findIndex(immutableKey => fKey === immutableKey && filter.immutable[immutableKey]) > -1
                // }).forEach(filterKey => routeModelData.setFetchOption(filterKey, filter[filterKey]));

                routeModelData.addListener({
                    id: 'TimetablesRouteListener',
                    setterFn: routes => {
                        setRoutes(routes);
                    },
                    sortFn: routeNumberSortFn,
                    loaded: routesById => {
                        setRoutes(values(routesById));
                        // setFilteredRoutes(values(routesById));
                        // TODO: filter the routes
                        // Object.keys(filter).forEach(filterKey => routeModelData.setFetchOption(filterKey, filter[filterKey]));
                    }
                });
                // const routesById = await routeModelData.getAll({force: true});
                // let routes = values(routesById);

                scheduleModelData.addListener({
                    id: 'TimetablesScheduleListener',
                    setterFn: setSchedules,
                    loaded: schedules => setSchedules(schedules)
                });
                // let schedules = await scheduleModelData.getAll()
                // console.log('schedule count:', Object.keys(schedules).length);
                // setSchedules(schedules)


                // transferModelData
                //     .setFetchOption('allStops', stopsById)
                //     .setFetchOption('allSchedules', schedules)
                // const allTransfers = await transferModelData.getAll()
                transferModelData.addListener({
                    id: 'TimetablesTransferListener',
                    setterFn: setAllTransfers,
                    loaded: transfers => setAllTransfers(transfers)
                });

                // routesModelExpiryService.init({
                //     apiKey: key,
                //     modelFilter: ['Stop', 'Transfer', 'Route', 'Schedule', 'RouteComment', 'StopComment'],
                // }).then(() => console.log('Routes Model Expiry Service started'))
                //
                // stopModelData.addListener({
                //     id: 'TimetablesStopListener',
                //     setterFn: setAllStops,
                //     loaded: stopsById => {
                //         setAllStops(stopsById)
                //     }
                // })
                //
                // routeModelData.addListener({
                //     id: 'TimetablesRouteListener',
                //     setterFn: routes => {
                //         setRoutes(routes)
                //     },
                //     sortFn: routeNumberSortFn,
                //     loaded: routesById => {
                //         setRoutes(values(routesById));
                //         setFilteredRoutes(values(routesById));
                //         // TODO: filter the routes
                //         // Object.keys(filter).forEach(filterKey => routeModelData.setFetchOption(filterKey, filter[filterKey]));
                //     }
                // });
                // scheduleModelData.addListener({
                //     id: 'TimetablesScheduleListener',
                //     setterFn: setSchedules,
                //     loaded: schedules => setSchedules(schedules)
                // })
                // transferModelData.addListener({
                //     id: 'TimetablesTransferListener',
                //     setterFn: setAllTransfers,
                //     loaded: transfers => setAllTransfers(transfers)
                // })

                // let time = Date.now()
                // let {stops: basicStops, schedules, operator, routes} = await API.get('public', `/jp/startup?_k=${key}&routes=true`,
                //     {headers: {'x-accept-encoding': 'gzip'}})
                //
                // basicStops = basicStops.map(s => {
                //     s.verified = 1
                //     return new Stop(s)
                // })
                // basicStops = keyBy(basicStops, 'stopId')
                // setAllStops(basicStops);
                // schedules = keyBy(schedules.map(s => new Schedule(s)), 'scheduleId')
                // setSchedules(schedules);
                // operator.features = new Features(operator.features);
                // setOperator(operator);
                // routes = routes.map(r => new BusRoute(r))
                // setRoutes(routes);
                // setFilteredRoutes(routes)
                // setFetching(false)
                //
                // console.log('Took', Date.now() - time, 'ms to startup')
                //
                // time = Date.now()
                //
                // API.get('public', `/jp/load?_k=${key}&routes=true`,
                //     {headers: {'x-accept-encoding': 'gzip'}})
                //     .then(({
                //                stops,
                //                transfers,
                //                routes
                //            }) => {
                //
                //         const allStops = keyBy(stops, 'stopId')
                //         Object.keys(basicStops).forEach(stopId => {
                //             allStops[stopId] = new Stop({...basicStops[stopId], ...allStops[stopId]})
                //         })
                //         setAllStops(allStops)
                //
                //         setRoutes(routes.map(r => {
                //             r = new BusRoute(r);
                //             if (allStops && r.stopTimes) {
                //                 r.setBaseStops(allStops);
                //             }
                //             return r;
                //         }));
                //         setAllTransfers(prepareTransfers(transfers, schedules, allStops));
                //         console.log('Took', Date.now() - time, 'ms to load')
                //
                //     })
                console.log('Getting Operator and Schedules for %s', key);
            } catch (e) {
                onError(e);
            }
        }

        onLoad().then('timetables loaded');
        ReactGA.send({hitType: 'pageview', page: '/', title: 'publishable-service-timetables'});

        return () => {
            stopModelData.removeListener('TimetablesStopListener');
            routeModelData.removeListener('TimetablesRouteListener');
            scheduleModelData.removeListener('TimetablesScheduleListener');
            transferModelData.removeListener('TimetablesTransferListener');
        };

    }, [user, operatorId, searchParams, setSelectedRouteIds, setRoutes, setFetching, setFilteredRoutes, setAllTransfers, setSchedules]);

    useEffect(() => {

        if (!allStops || !operator) {
            return;
        }
        const filter = getFilterFromParams(searchParams, null, allStops);
        console.log('Filter: ', filter);

        Auth.currentSession().catch(() => {
            if (filter?.stopId?.length) {
                setTitle(`${allStops[filter.stopId].stopName} Timetables`);
            } else if (filter?.stopIds?.length) {
                if (filter.stopIds.length === 1) {
                    setTitle(`${allStops[filter.stopIds[0]].stopName} Timetables`);
                } else {
                    setTitle(`${allStops[filter.stopIds[0]].stopName}  (+ ${filter.stopIds.length - 1} more) Timetables`);
                }
                // setTitle(`${allStops[filter.stopId].stopName} Timetables`)
                // } else if (filter?.routeIds?.length) {
                //     setTitle(`Timetables for ${filter?.routeIds?.length} routes`)

                // REMOVING "Filtered" as the filter options have been introduced in version 2.7.0
                // } else if (filter?.isFilter()) {
                //     setTitle(`Filtered Timetables`)
            } else if (operator?.operatorName) {
                setTitle(`${operator.operatorName} Timetables`);
            } else {
                setTitle(`All Timetables`);
            }
        });

        console.log('STOP:', allStops[filter.stopId]);
        if (filter?.stopId && allStops[filter.stopId]?.linkedStops?.length) {
            filter.stopIds = allStops[filter.stopId].linkedStops.map(s => s.stopId);
            filter.stopIds.push(filter.stopId);
        } else if (filter?.stopId) {
            filter.stopIds = [filter.stopId];
        }

    }, [operator, allStops, searchParams, setFilter]);

    useEffect(() => {
        if (allTransfers && routes && allStops && schedules) {
            routes.forEach(r => {
                r.calculateStartEnd({ensureStop: false});
                r.setTransfers(allTransfers, keyBy(routes, 'routeId'), true);
            });
            setFetching(false);
        }
    }, [setFetching, routes, allStops, allTransfers, schedules]);

    useEffect(() => {
        if (!fetching) {
            setFilter(getFilterFromParams(searchParams, routes, allStops));
            if (routes.length === 1) {
                setZoom([routes[0].routeId]);
            }
        }
    }, [setFilter, searchParams, routes, fetching, allStops]);

    useEffect(() => {
        if (allStops) {
            setSelectedStop(selectedStop => {
                if (selectedStop) {
                    return allStops[selectedStop.stopId];
                }
            });
        }
    }, [allStops, setSelectedStop]);

    useEffect(() => {
        if (fetching) {
            return;
        }
        // if (filter.stopId) {
        //     filter.stopIds = filter.stopIds || [filter.stopId];
        //     delete filter.stopId
        // }
        // // Set the filter for stops only once
        // if (filter && filter.stopIds && !filter.stops) {
        //     filter.stops = filter.stopIds.map(sId => allStops[sId]);
        //     filter.stops.forEach(stop => {
        //         if (stop?.linkedStops?.length) {
        //             const {startBell, startBellWindow, endBell, endBellWindow} = stop
        //             filter.stops = filter.stops.concat(stop.linkedStops.map(ls => ({
        //                 ...allStops[ls.stopId],
        //                 startBell, startBellWindow, endBell, endBellWindow
        //             })));
        //             filter.stopIds = filter.stops.map(s => s.stopId);
        //         }
        //     })
        //     filter.stops.forEach(stop => {
        //         const routeIdsForStop = getRouteIdsForStopId(routes, stop.stopId);
        //         if (Array.isArray(routeIdsForStop)) {
        //             filter.routeIds = uniq(routeIdsForStop);
        //         }
        //     })
        // }
        const time = Date.now();
        console.log('ROUTES: ', routes.length);
        let _filteredRoutes = routes ? routes.filter(r => {
            const routeFilter = !filter.routeIds || !filter.routeIds.length || filter.routeIds.find(filteredId => r.routeId === filteredId);
            const typeFilter = (filter.school && r.routeType === 'School') || (filter.regular && r.routeType === 'Regular');
            const directionsFilter = !filter.directions || !filter.directions.length || filter.directions === 'all' || filter.directions.findIndex(filteredDirection => r.direction === filteredDirection) > -1;
            return r.published === 1 && routeFilter && typeFilter && directionsFilter;
        }) : [];

        console.log('FILTERED: ', _filteredRoutes.length);

        _filteredRoutes = _filteredRoutes.filter(r => {
            return r.services.some(trip => {
                return trip.scheduleIds.some(scheduleId => {
                    const schedule = schedules[scheduleId];
                    console.log('Checking schedule ID %s called %s: Obsolete: %s', scheduleId, schedule?.scheduleName, schedule?.isObsolete());
                    return schedule && !schedule.isObsolete() &&
                        (!filter.date
                            || !filter.date.isValid()
                            || schedule.isActive(filter.date, true));
                });
            });
        });
        console.log('FILTERED: ', _filteredRoutes.length);
        if (_filteredRoutes?.length && filter.search) {
            _filteredRoutes = _filteredRoutes.filter(r => {
                return r.routeNumber?.toLowerCase().includes(filter.search) ||
                    r.routeName?.toLowerCase().includes(filter.search) ||
                    r.routeDetails?.toLowerCase().includes(filter.search) ||
                    r.stops.some(s => s.stopName?.toLowerCase().includes(filter.search));
            });
        }
        console.log('FILTERED: ', _filteredRoutes.length);
        if (_filteredRoutes?.length && filter.truncate && filter.stops?.length) {
            const checkArrival = (route, stop, stopTime) => {
                if (stop) {
                    if (!stop.startBell || !stop.startBellWindow) {
                        return true;
                    }
                    const departureTime = getArrivalTimeAsSecondsSinceMidnight(route, stopTime);
                    return (stop.startBell - stop.startBellWindow) <= departureTime && departureTime <= stop.startBell;
                }
                return false;
            };
            const checkDeparture = (route, stop, stopTime) => {
                if (stop) {
                    if (!stop.endBell || !stop.endBellWindow) {
                        return true;
                    }
                    const departureTime = getDepartureTimeAsSecondsSinceMidnight(route, stopTime);
                    return stop.endBell <= departureTime && departureTime <= (stop.endBell + stop.endBellWindow);
                }
                return false;
            };
            _filteredRoutes = _filteredRoutes.filter(r => {
                if (r.direction === 'AM' || r.direction === 'Inbound') {
                    r.services = r.services.filter(trip => {
                        return trip.stopTimes.some(st => {
                            const stop = find(filter.stops, ['stopId', st.stopId]);
                            return checkArrival(trip, stop, st);
                        });
                    });
                    if (!r.services?.length) {
                        return false;
                    }
                    const stops = r.services[0].stopTimes.filter(st => {
                        const stop = find(filter.stops, ['stopId', st.stopId]);
                        return checkArrival(r, stop, st);
                    });
                    if (stops.length) {
                        r.calculateStartEnd({lastStop: stops[0]});
                        return true;
                    }
                    return false;
                }
                if (r.direction === 'PM' || r.direction === 'Outbound') {
                    r.services = r.services.filter(trip => {
                        return trip.stopTimes.some(st => {
                            const stop = find(filter.stops, ['stopId', st.stopId]);
                            return checkDeparture(trip, stop, st);
                        });
                    });
                    if (!r.services?.length) {
                        return false;
                    }
                    const stops = r.services[0].stopTimes.filter(st => {
                        const stop = find(filter.stops, ['stopId', st.stopId]);
                        return checkDeparture(r, stop, st);
                    });
                    if (stops.length) {
                        r.calculateStartEnd({firstStop: stops[0]});
                        return true;
                    }
                    return false;
                }
                return false;
            });
        }
        console.log('FILTERED: ', _filteredRoutes.length);
        setFilteredRoutes(_filteredRoutes.sort(routeNumberSortFn));
        setSelectedSchedule(selectedSchedule => {
            _filteredRoutes.forEach(r => selectedSchedule[r.routeId] = r.services[0]?.scheduleIds[0]);
            return {...selectedSchedule};
        });


        console.log(`Filtered in${Date.now() - time}ms`);
    }, [routes, allTransfers, filter, setFilteredRoutes, setZoom, schedules, allStops, setSelectedSchedule, fetching]);

    const getDirectionOptions = useCallback(() => {
        if (!routes) {
            return;
        }
        let directions = [{label: 'All', value: 'All', disabled: false}];
        if (routes.some(r => ['AM', 'PM'].includes(r.direction))) {
            directions = directions.concat(schoolDirectionOptions);
        }
        if (routes.some(r => ['Inbound', 'Outbound'].includes(r.direction))) {
            directions = directions.concat(regDirectionOptions);
        }
        // Don't show anything if only All
        if (directions.length === 1) {
            return [];
        }
        return directions;
    }, [routes]);

    const getScheduleOptions = useCallback((route) => {
        return uniqBy(flatten(route.services.map(trip => trip.scheduleIds.map(sId => schedules[sId]))), 'scheduleId').filter(s => !!s).map(s => ({
            label: s.scheduleName,
            value: s.scheduleId,
            disabled: false
        }));
    }, [schedules]);

    const setFilterDebounced = useDebounce(setFilter, 250);

    return (
        <div className="Timetable">
            {!startup ? (
                <>
                    {title &&
                        <h1 className="page-title header-title mb-0">{title}</h1>}
                    <div className="d-flex align-items-center justify-content-end filter-options-main top-float">
                        <div className="justify-content-end mr-3">
                            <InputGroup className="search-filter">
                                <FormControl
                                    type="search"
                                    placeholder="Filter"
                                    className=""
                                    size="sm"
                                    aria-label="Filter"
                                    defaultValue={filter?.search}
                                    onChange={({target: {value: search}}) => {
                                        search = search.toLowerCase();
                                        setFilterDebounced(filter => {
                                            return {...filter, search};
                                        });
                                    }}
                                />
                                <InputGroup.Text id="basic-addon1"><SearchOutlined/></InputGroup.Text>
                            </InputGroup>
                        </div>
                        <div className="filter-calendar mr-3">
                            <DatePicker placeholder="Select date"
                                        format={{
                                            format: DATE_STRING,
                                            type: 'mask',
                                        }}
                                        value={filter?.date}
                                        onChange={(date, datestr) => {
                                            setFilter(filter => {
                                                if (datestr?.length) {
                                                    filter.date = dayjs(datestr, DATE_STRING);
                                                } else {
                                                    delete filter.date;
                                                }
                                                return {...filter};
                                            });
                                        }}/>
                        </div>
                        <div className="filter-dropdown">
                            <Dropdown overlayClassName="filter-dropdown-overlay"
                                      menu={{items}}
                                      trigger={['click']}
                                      dropdownRender={(menu) => (
                                          <Radio.Group
                                              options={getDirectionOptions()}
                                              onChange={({target: {value}}) => setFilter(filter => ({
                                                  ...filter,
                                                  directions: value === 'All' ? null : [value]
                                              }))}
                                              defaultValue={'All'}
                                              value={filter.directions?.length ? filter.directions[0] : 'All'}
                                              disabled={fetching || !routes?.length}
                                              optionType="button"
                                          />
                                      )}
                            >
                                {/* <a onClick={(e) => e.preventDefault()}><Refine/></a> */}
                                <Button type="primary" className="icon-button"
                                        icon={<Refine/>}> Refine</Button>
                            </Dropdown>

                            {/*<Button type="primary" className="icon-button"*/}
                            {/*        icon={<CommentOutlined/>}*/}
                            {/*        onClick={() => {*/}
                            {/*            setChatContext(cc => cc.clone({type: 'all', setFocusPt, setSelectedRouteIds: setZoom}));*/}
                            {/*            setChatOpen(o => !o);*/}
                            {/*        }}*/}
                            {/*>Comments</Button>*/}
                        </div>
                    </div>
                    <div className="card-main map-holder-wrap no-pad d-flex">
                        <div className="map-holder" style={{width: '65%'}}>
                            <div className="MapPlaceholder">
                                <RouteMapViewer routes={routes}
                                                allStops={allStops}
                                                verifiedOnly={true}
                                                fetching={fetching}
                                                allowComments={allowComments}
                                                setAllowComments={setAllowComments}
                                                showComments={showComments}
                                                setShowComments={setShowComments}
                                                focusPt={focusPt}
                                                setFocusPt={setFocusPt}
                                                filteredRoutes={filteredRoutes}
                                                selectedRouteIds={union(zoom, selectedRouteIds)}
                                                highlightedRouteIds={union(zoom, highlightedRouteIds)}
                                                selectedStop={selectedStop}
                                                showWpSelector={union(selectedRouteIds, zoom)?.length === 1}
                                                handleCopyFilter={() => {
                                                }}
                                                handleSelectedRoutes={(routes) => {
                                                    setFilter(filter => {
                                                        filter.routeIds = routes.map(r => r.routeId);
                                                        return {...filter};
                                                    });
                                                    // setZoom(routes.map(r => r.routeId));
                                                }}
                                                handleSelectedRoute={(route) => {
                                                    if (highlightedRouteIds.indexOf(route.routeId) > -1) {
                                                        setHighlightedRouteIds(selectedRouteIds => selectedRouteIds.filter(rId => rId !== route.routeId));
                                                        setZoom(zoom => zoom.filter(rId => rId !== route.routeId));
                                                    } else {
                                                        setHighlightedRouteIds(selectedRouteIds => selectedRouteIds.concat(route.routeId));
                                                        setZoom(zoom => zoom.concat(route.routeId));
                                                    }
                                                }}
                                                focusTransfers={focusTransfers}
                                                focusStop={focusStop}
                                                setFocusStop={setFocusStop}
                                                handleStopClick={(stop) => setSelectedStop(stop)}
                                                setFilter={setFilter}
                                                filter={filter}
                                                operatorId={operatorId}
                                                zoom={zoom}
                                                setZoom={setZoom}/>
                            </div>
                        </div>
                        <TimetableRouteList filteredRoutes={filteredRoutes}
                                            setHighlightedRouteIds={setHighlightedRouteIds}
                                            highlightedRouteIds={highlightedRouteIds}
                                            setZoom={setZoom}
                                            zoom={zoom}
                                            showComments={showComments}
                                            selectedSchedule={selectedSchedule}
                                            setSelectedSchedule={setSelectedSchedule}
                                            fetching={fetching}
                                            selectedStop={selectedStop}
                                            setSelectedStop={setSelectedStop}
                                            setFocusStop={setFocusStop}
                                            focusStop={focusStop}
                                            schedules={schedules}
                                            getScheduleOptions={getScheduleOptions}
                                            allStops={allStops}
                                            operator={operator}
                                            focusTransfers={focusTransfers}
                                            setFocusTransfers={setFocusTransfers}
                        />
                        {/*<Tabs style={{width: "35%"}} items={[{*/}
                        {/*    key: 'routes',*/}
                        {/*    label: 'Routes',*/}
                        {/*    children: <TimetableRouteList filteredRoutes={filteredRoutes}*/}
                        {/*                                  setHighlightedRouteIds={setHighlightedRouteIds}*/}
                        {/*                                  highlightedRouteIds={highlightedRouteIds}*/}
                        {/*                                  setZoom={setZoom}*/}
                        {/*                                  zoom={zoom}*/}
                        {/*                                  selectedSchedule={selectedSchedule}*/}
                        {/*                                  setSelectedSchedule={setSelectedSchedule}*/}
                        {/*                                  fetching={fetching}*/}
                        {/*                                  selectedStop={selectedStop}*/}
                        {/*                                  setSelectedStop={setSelectedStop}*/}
                        {/*                                  setFocusStop={setFocusStop}*/}
                        {/*                                  focusStop={focusStop}*/}
                        {/*                                  schedules={schedules}*/}
                        {/*                                  getScheduleOptions={getScheduleOptions}*/}
                        {/*                                  allStops={allStops}*/}
                        {/*                                  operator={operator}*/}
                        {/*                                  focusTransfers={focusTransfers}*/}
                        {/*                                  setFocusTransfers={setFocusTransfers}*/}
                        {/*    />*/}
                        {/*}, {*/}
                        {/*    key: 'comments',*/}
                        {/*    label: 'Comments',*/}
                        {/*    children: <div className={"w-100"}><ChatComponent/></div>*/}
                        {/*}]}*/}
                        {/*      defaultActiveKey="routes"/>*/}

                    </div>
                </>
            ) : <LoadMessage message={'Loading Timetables...'} size={'lg'}/>}

        </div>


    ); //End return
}

export default React.memo(Timetables);
