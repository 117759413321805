import React, {memo, useState} from 'react';
import PrintOptions from '../../components/PrintOptions';
import ShiftBatPrint from '../ShiftBat/ShiftBatPrint';
import {Typography} from 'antd';

const {Text} = Typography;


function WorkDiaryPrintView({allStops, allRoutes, workDiaries, schedules}) {

    const [printLayout, setPrintLayout] = useState('lone'); // lone, ltwo
    const [printMode, setPrintMode] = useState('lg'); // lg, md, sm
    const [showMap, setShowMap] = useState(false);

    return (<div>
            <PrintOptions title={'Print Work Diary'} printLayout={printLayout} setPrintLayout={setPrintLayout}
                          printMode={printMode}
                          setPrintMode={setPrintMode} showMap={showMap} setShowMap={setShowMap}/>
            {workDiaries.map(workDiary => (
                <div className={'page-break mb-5'}>
                    <div className={'work-diary-title'}>{workDiary.name} - <span>{workDiary.day}</span></div>
                    <ShiftBatPrint shiftBat={workDiary.workItems.map(wi => wi.getDuty())} schedules={schedules}
                                   printLayout={printLayout} printMode={printMode} showMap={showMap}
                                   allStops={allStops} allRoutes={allRoutes} customer={workDiary.customer} 
                                   allocation={workDiary}/>
                </div>
            ))}
        </div>
    );
}

export default memo(WorkDiaryPrintView);
