import React, { useMemo } from "react";
import {Modal} from "antd";
import ShiftBatPrintView from "./ShiftBatPrintView";

function ShiftBatPrintModal({
                                apiKey,
                                shiftBat,
                                allStops, allRoutes,
                                visible,
                                schedules,
                                setVisible,
                                allCharters,
                                allVehicles,
                                employeeData,
                                workItem
                            }) {

        const customer = useMemo(() => {
            if(shiftBat?.charter) {
                const charterData = Object.values(allCharters || {}).find(charter => 
                    charter?.duties?.some(duty => duty.shiftBatId === shiftBat.shiftBatId)
                ); 
                return charterData?.customer
            }
            return null
        }, [shiftBat, allCharters])

        const [employee, vehicle] = useMemo(() => {
            if(workItem) {
                const vehicle = workItem?.vehicleId ? allVehicles?.[workItem?.vehicleId] : null
                const employee = workItem?.employeeId && employeeData ? employeeData?.getEmployee?.(workItem?.employeeId) : null
                return [employee, vehicle]
            }
            return [null, null]
        }, [workItem, allVehicles, employeeData])

    return (
        <Modal
            width={1000}
            style={{ top: 50 }}
            open={visible}
            destroyOnClose
            okText={`Print`}
            onCancel={() => setVisible(null)}
            onOk={() => window.print()}
        >
            <ShiftBatPrintView
                allRoutes={allRoutes}
                allStops={allStops}
                operatorId={apiKey}
                shiftBat={shiftBat}
                schedules={schedules}
                customer={customer}
                allocation={{
                    employee: employee,
                    vehicle: vehicle
                }}
            />
        </Modal>
    );
}

export default React.memo(ShiftBatPrintModal)
