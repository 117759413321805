import {useEffect, useState} from 'react';
import {routeModelData} from '../services/ModelService';
import {values} from 'lodash';

const useAllRoutes = () => {
    const [allRoutes, setAllRoutes] = useState(null);
    const [allRoutesArray, setAllRoutesArray] = useState([]);

    useEffect(() => {
        const routesListener = routeModelData.addListener({
            setterFn: setAllRoutes, loaded: routes => {
                setAllRoutes(routes);
            }
        });

        return () => {
            if (routesListener)
                routeModelData.removeListener(routesListener);
        };
    }, []);

    useEffect(() => {
        if (!allRoutes) return;
        setAllRoutesArray(values(allRoutes));
    }, [allRoutes, setAllRoutesArray]);

    return {
        allRoutes, allRoutesArray
    };
};

export default useAllRoutes;