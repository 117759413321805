import React, { useCallback, useEffect, useState, useMemo } from "react";
import "./Charters.css";
import { Col, Tooltip, Typography, Steps, Button } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";
import { ReactComponent as Calender } from "../../assets/icons/Calendar.svg";
import { ReactComponent as Bus } from "../../assets/icons/Bus.svg";
import { ReactComponent as Rostering } from "../../assets/icons/Rostering.svg";
import { ReactComponent as Quote } from "../../assets/icons/Quote.svg";
import { ReactComponent as Warning } from "../../assets/icons/Warning.svg";
import { ShiftBatRowType } from "../../model/shiftBat";

const { Text } = Typography;

function getItem({ label, key, icon, children, type, disabled }) {
    return {
        key,
        icon,
        children,
        label,
        type,
        disabled,
    };
}

function CharterMenu({
    activeKey,
    charter,
    disabled = { charter: false, itinerary: false, duty: false, quote: false },
    hide = { charter: false, itinerary: false, duty: false, quote: false },
    save,
    validFn,
    editMode,
    updated,
}) {
    const history = useHistory();
    const location = useLocation();
    const { setBreadcrumb } = useAppContext();
    setBreadcrumb(charter?.name || "New Charter");
    const [warning, setWarning] = useState({ charter: "", itinerary: "", duty: "", quote: "" });
    const [current, setCurrent] = useState(activeKey || 1);

    const steps = useMemo(() => [
        {
            title: "Charter Details",
            content: "Charter Details",
            disabled: disabled.charter,
            url: `/charters/${charter.charterId}`,
        },
        {
            title: "Charter Itinerary",
            content: "Charter Itinerary",
            disabled: disabled.itinerary,
            url: `/charters/${charter.charterId}/itinerary`,
        },
        {
            title: "Driver Duty",
            content: "Driver Duty",
            disabled: disabled.duty,
            url: `/charters/${charter.charterId}/duty`,
        },
        {
            title: "Quote",
            content: "Quote",
            disabled: disabled.quote,
            url: `/charters/${charter.charterId}/quote`,
        },
    ], [disabled, charter.charterId]);

    const next = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await save();
        setCurrent(current + 1);
    };
    const prev = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setCurrent(current - 1);
    };
    const skipToStep = (_step) => {
        setCurrent(_step + 1);
    }

    const step = steps.map((item) => ({
        key: item.title,
        title: item.title,
        disabled: item.disabled,
    }));

    useEffect(() => {
        const stepUrl = steps[current - 1].url;
        if (location.pathname !== stepUrl) {
            const correctStep = steps.findIndex((step) => step.url === location.pathname);
            if (correctStep !== -1) {
                setCurrent(correctStep + 1);
            }
        }
      }, [current, location, steps]);

    const missingTrips = useCallback(() => {
        const missingTrips = [];
        charter.itinerary.forEach((route) => {
            route.services.forEach((trip) => {
                if (
                    !charter.duties.find((duty) =>
                        duty.rows
                            .filter((r) => r.type === ShiftBatRowType.service)
                            .find((row) => row.tripId === trip.tripId || row.trip?.tripId === trip.tripId)
                    )
                ) {
                    missingTrips.push("Charter leg " + route.routeNumber + " : " + trip.getStartTime());
                }
            });
        });
        return missingTrips;
    }, [charter.itinerary, charter.duties]);

    useEffect(() => {
        if (charter.duties?.length) {
            let missingTrips = [],
                missingCalendars = false,
                missingVehicleTypes = false;
            charter.itinerary.forEach((route) => {
                route.services.forEach((trip) => {
                    if (
                        !charter.duties.find((duty) =>
                            duty.rows
                                .filter((r) => r.type === ShiftBatRowType.service)
                                .find((row) => row.tripId === trip.tripId || row.trip?.tripId === trip.tripId)
                        )
                    ) {
                        missingTrips.push("Charter leg " + route.routeNumber + " : " + trip.getStartTime());
                    }
                });
            });
            missingTrips = missingTrips.map((trip) => <Text style={{ color: "white" }}>{trip}</Text>);
            if (missingTrips.length) {
                setWarning((w) => ({
                    ...w,
                    duty: (
                        <div>
                            <div>
                                <strong>Missing trips:</strong>
                            </div>
                            <div>{missingTrips}</div>
                        </div>
                    ),
                }));
                return;
            }
            missingCalendars = charter.duties.every((duty) => !duty.scheduleIds?.length && !duty.dates?.length);
            missingVehicleTypes = charter.duties.every((duty) => !duty.vehicleTypeId?.length);
            setWarning((w) => ({
                ...w,
                duty:
                    missingCalendars || missingVehicleTypes ? (
                        <div>
                            {missingCalendars ? (
                                <div>
                                    <strong>
                                        Missing Calendar/Dates:{" "}
                                        {charter.duties
                                            .filter((duty) => !duty.scheduleIds?.length && !duty.dates?.length)
                                            .map((duty) => duty.shiftBatNumber)
                                            .join(", ")}
                                    </strong>
                                </div>
                            ) : null}
                            {missingVehicleTypes ? (
                                <div>
                                    <strong>
                                        Missing vehicle type:{" "}
                                        {charter.duties
                                            .filter((duty) => !duty.vehicleTypeId?.length)
                                            .map((duty) => duty.shiftBatNumber)
                                            .join(", ")}
                                    </strong>
                                </div>
                            ) : null}
                        </div>
                    ) : null,
            }));
        }
    }, [charter.itinerary, charter.duties, setWarning]);

    const items = useCallback(() => {
        if (!charter || !hide || !disabled) {
            return [];
        }
        const items = [];
        if (!hide.charter) {
            items.push(
                getItem({
                    label: (
                        <span>
                            Customer Details{" "}
                            {warning.charter ? (
                                <Tooltip placement={"bottom"} title={warning.charter} className="ml-2" overlayClassName="fixed-tooltip">
                                    <Warning className="header-icon w-16" />
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </span>
                    ),
                    key: "1",
                    icon: <Calender width={20} height={20} />,
                    disabled: disabled.charter,
                })
            );
        }
        if (!hide.itinerary) {
            items.push(
                getItem({
                    label: (
                        <span>
                            Customer Itinerary{" "}
                            {warning.itinerary ? (
                                <Tooltip placement={"bottom"} title={warning.itinerary} className="ml-2" overlayClassName="fixed-tooltip">
                                    <Warning className="header-icon w-16" />
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </span>
                    ),
                    key: "2",
                    icon: <Bus width={20} height={20} />,
                    disabled: disabled.itinerary,
                })
            );
        }

        if (!hide.duty) {
            items.push(
                getItem({
                    label: (
                        <span>
                            Driver Duty{" "}
                            {warning.duty ? (
                                <Tooltip placement={"bottom"} title={warning.duty} className="ml-2" overlayClassName="fixed-tooltip">
                                    <Warning className="header-icon w-16" />
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </span>
                    ),
                    key: "3",
                    icon: <Rostering width={20} height={20} />,
                    disabled: disabled.duty,
                })
            );
        }

        if (!hide.quote) {
            items.push(
                getItem({
                    label: (
                        <span>
                            Quote{" "}
                            {warning.quote ? (
                                <Tooltip placement={"bottom"} title={warning.quote} className="ml-2" overlayClassName="fixed-tooltip">
                                    <Warning className="header-icon w-16" />
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </span>
                    ),
                    key: "4",
                    icon: <Quote width={20} height={20} />,
                    disabled: disabled.quote,
                })
            );
        }
        return items;
    }, [charter, disabled, hide, warning]);

    const onMenuSelect = useCallback(
        ({ key }) => {
            const menu = items().find((item) => item.key === key.toString()).key;
            const currentPath = history.location.pathname;
            let newPath = "";
            switch (menu) {
                case "1":
                    newPath = `/charters/${charter.charterId}`;
                    break;
                case "2":
                    newPath = `/charters/${charter.charterId}/itinerary`;
                    break;
                case "3":
                    newPath = `/charters/${charter.charterId}/duty`;
                    break;
                case "4":
                    newPath = `/charters/${charter.charterId}/quote`;
                    break;
                default:
                    break;
            }
            if (newPath && newPath !== currentPath) {
                history.push(newPath);
            }
        },
        [history, charter, items]
    );

    useEffect(() => {
        if (current && charter?.charterId !== '_') {
            onMenuSelect({ key: current });
        }
    }, [current, charter]);

    return (
        <Col xs={24} lg={24} className="no-print">
            {/* <div className="profile-summary">
                <h2>{charter?.name || "New Charter"}</h2>
                <div className="text-secondary">{charter?.date}</div>
            </div> */}

            <div className="StepsActions">
                {current > 1 ? (
                    <Button onClick={prev} className="secondary-btn-sm">
                        Previous
                    </Button>
                ) : (
                    <Button onClick={prev} disabled>
                        Previous
                    </Button>
                )}
                <Steps current={current - 1} items={step} className="StepsMenu" onChange={skipToStep}/>
                {current < steps.length && (
                    <Button type="primary" className="primary-btn-sm" onClick={next} disabled={!validFn()}>
                        Next
                    </Button>
                )}
                {current === steps.length && (
                    <Button type="primary" className="primary-btn-sm" onClick={save} disabled={!validFn()}>
                        Save
                    </Button>
                )}
            </div>
            {/* <Card
                title={
                    <Flex justify="space-between">
                        <div>{steps[current - 1].content}</div>
                    </Flex>
                }
                bordered={false}
                className="card-main card-info"
            ></Card> */}

            {/* <Menu
                className="profile-menu"
                mode="inline"
                theme="light"
                items={items()}
                selectedKeys={[activeKey]}
                onSelect={onMenuSelect}
            /> */}
        </Col>
    );
}

export default React.memo(CharterMenu);
