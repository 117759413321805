import React, {memo, useState, useEffect} from 'react';
import {Button, Modal, Switch, Typography} from 'antd';
import {operatorModelData} from '../../services/ModelService';
import {useHistory} from 'react-router-dom';
import {SyncOutlined} from '@ant-design/icons';
import {useAppContext} from '../../libs/contextLib';

const Paragraph = Typography.Paragraph;

export const ComplianceDisclaimerModal = memo(({}) => {
    const history = useHistory();
    const {operator, setOperator} = useAppContext();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const hasAgreed = operator.opts?.compliance?.accept;

    useEffect(() => {
        if (!hasAgreed) {
            setVisible(true);
        }
    });

    return (operator ?
            <>
                {/* <Button type="primary" className={`icon-button btn-filled ${operator.opts?.compliance?.accept ? 'active' : ''}`} icon={loading ? <SyncOutlined spin/> : null}
                        onClick={() => setVisible(true)}>Compliance</Button> */}
                <Modal
                    open={visible}
                    width={550}
                    title={`Disclaimer for Fatigue Management`}
                    closeable={true}
                    onCancel={() => {
                        if (hasAgreed) {
                            setVisible(false);
                        } else {
                            history.push('/');
                        }
                    }}
                    destroyOnClose={true}
                    footer={[
                        <Button key="back" type={hasAgreed ? 'primary' : ''} className="btn-secondary" onClick={() => {
                            if (hasAgreed) {
                                setVisible(false);
                            } else {
                                history.push('/');
                            }
                        }}>OK</Button>
                    ]}
                >
                    <div className="compliance-check pt-4 pb-2">
                        <Paragraph>
                            <p>The fatigue management functionality provided by Busable is intended solely as a guidance tool to assist users in understanding and monitoring potential breaches of fatigue management rules as outlined in the National Heavy Vehicle Legislation (NHVL).</p>
                            <p><b>⚠️ This system is advisory only and does not replace or supersede your legal obligations under the NHVL or any other applicable laws.</b></p>
                            <p>Users must independently verify compliance with relevant regulations and apply their own judgment when making operational decisions.</p>
                        </Paragraph>

                        <Paragraph>Do you agree? <Switch checkedChildren="Yes" loading={loading} unCheckedChildren="No" checked={operator.opts?.compliance?.accept} className="default-switch"
                                onChange={async checked => {
                                    setLoading(true);
                                    const _operator = {...operator, opts: {...operator.opts, compliance: {accept: checked}}};
                                    try {
                                        await operatorModelData.save(_operator);
                                    } catch (e) {
                                        console.log('Error saving operator: ', e);
                                    }
                                    setOperator(_operator);
                                    setLoading(false);
                                }}/></Paragraph>
                    </div>
                </Modal>
            </> : <></>
    );
});
