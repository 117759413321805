import React, {useCallback, useEffect, useState} from 'react';
import './Charters.css';
import {charterModelData, routeModelData, stopModelData} from '../../services/ModelService';
import {Charter, CHARTER_STATUS} from '../../model/charter';
import useModel from '../../hooks/useModel';
import SaveMenu from '../../components/SaveMenu';
import LoadMessage from '../../components/LoadMessage';
import {Button, Card, Col, DatePicker, Divider, Flex, Input, InputNumber, List, Row, Select, Typography} from 'antd';
import CharterMenu from './CharterMenu';
import {isEmpty, values} from 'lodash';
import dayjs from '../../dayjs';
import {dayjsToSecsSinceMidnight, secsSinceMidnightToDayjs} from '../../model/timeFilter';
import TimeField from 'react-simple-timefield';
import {FilterTags} from '../../containers/FilterTag';
import {filterItems} from './Charters';
import {DATE_STRING} from '../../model/schedule';
import useAllStops from '../../hooks/useAllStops';
import AddressLookup from '../../components/AddressLookup';
import {ulid} from 'ulid';
import {BusRoute} from '../../model/busRoute';
import {PlusOutlined} from '@ant-design/icons';
import CustomerDetailsModal from '../Customers/CustomerDetailsModal';
import useAllCustomers from '../Customers/useAllCustomers';

const {TextArea} = Input;
const {Paragraph} = Typography;

const Destinations = ({destinations = [], setDestinations}) => {
    const [address, setAddress] = useState(null);
    const [name, setName] = useState(null);
    const [lat, setLat] = useState(0.0);
    const [lon, setLon] = useState(0.0);
    const [time, setTime] = useState(0);

    const addDestination = () => {
        const id = ulid();
        setDestinations([...destinations, {
            stopId: id,
            stopTimeId: id,
            address,
            stopName: name,
            lat,
            lon,
            arriveSecs: time,
            departSecs: time,
            timingPoint: true,
            stopType: 'charter'
        }]);
    };

    return (
        <div>
            <Flex justify={'space-between'}>
                <AddressLookup address={address}
                               limit={20}
                               placeholder="Enter Destination Address"
                               setAddress={address => {
                                   setAddress(address);
                                   const [name, suburb, state, postcode, lat, lon] = address.split('|');
                                   setName(name);
                                   setLat(parseFloat(lat));
                                   setLon(parseFloat(lon));
                               }}/>
                <TimeField
                    className="inputtext-small sb-time-edit"
                    value={
                        Number.isFinite(time) && time > 0
                            ? secsSinceMidnightToDayjs(time).format('HH:mm')
                            : 0
                    }
                    status={!time ? 'error' : ''}
                    onChange={(e, value) => {
                        value = dayjs(value, 'HH:mm');
                        setTime(dayjsToSecsSinceMidnight(value));
                    }}
                />
                <Button type="primary" onClick={addDestination} style={{marginBottom: '10px'}}>
                    Add Item
                </Button></Flex>
            <List
                bordered
                dataSource={destinations}
                renderItem={(item, index) => (
                    <List.Item key={index}>
                        {secsSinceMidnightToDayjs(item.time).format('HH:mm')} {item.name}
                    </List.Item>
                )}
                style={{width: '300px'}}
            />
        </div>
    );
};

function CharterDetails() {
    // const {operator} = useAppContext();
    const {allDepots} = useAllStops();
    const {allCustomers} = useAllCustomers();
    // const {allSchedules} = useAllSchedules();
    const [customers, setCustomers] = useState([]);
    const [newCustomerVisible, setNewCustomerVisible] = useState(false);

    const {
        model: charter,
        setModel: setCharter,
        initialModelRef: initialCharterRef,
        mode,
        setMode,
        controls,
    } = useModel({
        modelService: charterModelData,
        initialInstance: new Charter({}),
    });

    useEffect(() => {
        if(allCustomers) {
            setCustomers(values(allCustomers))
        }
    }, [allCustomers]);

    const setCharterWith = useCallback(
        (propName, value) => {
            if (!Array.isArray(propName)) {
                propName = [propName];
                value = [value];
            }
            setCharter((charter) => {
                const newCharter = new Charter({...charter});
                propName.forEach((prop, idx) => {
                    if (value[idx] === undefined || value[idx] === null) {
                        delete newCharter[prop];
                        return;
                    }
                    newCharter[prop] = value[idx];
                });
                return newCharter;
            });
        },
        [setCharter]
    );

    const saveCharter = useCallback(async () => {
        let savingObject = [charter];

        // const isNewCharter = charter.charterId === "_";
        // if (isNewCharter) {
        //     const itinerary = new ItineraryShift();
        //     charter.itineraryId = itinerary.charterId;
        //     savingObject = [charter, itinerary];
        // }
        if (!charter.itinerary?.length && charter.destinations?.length) {
            // const stops = charter.destinations.map(destination => {
            //     const {stopId, stopName, stopType, lat, lon} = destination;
            //     return {stopId, stopName, stopType, lat, lon};
            // })
            // await stopModelData.save(stops);
            // const stopTimes = charter.destinations.map(destination => {
            //     const {stopTimeId, stopId, arriveSecs, departSecs} = destination;
            //     return {stopTimeId, stopId, arriveSecs, departSecs};
            // });
            const routeId = ulid();
            const route = new BusRoute({
                routeId,
                routeNumber: 'CHA-1',
                routeName: charter.name + ' Leg 1',
            });
            route.addTrip();

            await Promise.all(charter.destinations.map(async (destination, i) => {
                await route.addStop(destination, 'append', true);
            }));

            charter.itinerary = [route];
            // charter.itinerary = await ShiftBat.fromCharterDetails({charter, depot: values(allDepots)?.[0], deadrunModelData, roundtrip});
            await routeModelData.save(route);
            await stopModelData.save(route.stops);

        }

        await charterModelData.save(savingObject);//.then(async (res) => {
        console.log('Charter saved.');
        setMode({edit: false});
        //});
    }, [charter, setMode, allDepots]);

    useEffect(() => {
        const calculateDriverHours = () => {

            // Create Date objects from the date strings
            const start = new Date(charter.startDate);
            const end = new Date(charter.endDate);

            // Add the seconds since midnight to the start and end times
            start.setSeconds(charter.startTime);
            end.setSeconds(charter.endTime);

            // Calculate the difference in milliseconds
            const diffInMilliseconds = end - start;

            // Convert the difference to hours
            const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

            setCharterWith('driverHours', diffInHours.toFixed(2));
        };
        if (charter.startDate && charter.startTime && charter.endDate && charter.endTime) {
            calculateDriverHours();
        }
    }, [charter.startDate, charter.startTime, charter.endDate, charter.endTime, setCharterWith]);

    const isCharterDetailsValid = () => {
        // check if required fields are filled
        return charter.isValid() && charter.name && (charter.charterType === 'single' ? charter.startDate : true);
    }

    return (
        <div className="charter-details w-secondary-menu">
            {charter ? (
                <>
                    <SaveMenu
                        save={saveCharter}
                        editMode={mode.edit}
                        id={charter.charterId}
                        setEditMode={(edit) => setMode({edit})}
                        controls={controls}
                        updated={controls.updated}
                        modelInstance={charter}
                        initialInstanceRef={initialCharterRef}
                        listUrl={`/charters`}
                        modelService={charterModelData}
                        validFn={() => isCharterDetailsValid()}
                        closeView={true}
                        isCharter={true}
                    />
                    <Row gutter={[0, 20]} className="w-100">
                        {!mode.overview && (
                            <CharterMenu
                                charter={charter}
                                activeKey={1}
                                setCharter={setCharter}
                                disabled={{
                                    charter: false,
                                    itinerary: mode.edit || charter.charterId === '_',
                                    duty: mode.edit || !charter.itinerary?.length,
                                    quote: mode.edit || !charter.duties?.length,
                                }}
                                save={saveCharter}
                                validFn={() => {
                                    return isCharterDetailsValid()
                                }}
                                editMode={mode.edit}
                            />
                        )}
                        <Col xs={24} lg={24}>
                            <Row gutter={[20, 20]}>
                                <Col xs={24}>
                                    <Card
                                        title={
                                            <Flex justify="space-between">
                                                <div>Charter Details</div>
                                            </Flex>
                                        }
                                        bordered={false}
                                        className="card-main card-info"
                                    >
                                        <Row gutter={[30, 30]}>
                                            <Col xs={24} lg={8}>
                                                <label>Charter Name</label>
                                                {mode.edit ? (
                                                    <Input
                                                        placeholder="Enter Charter Name"
                                                        status={!charter.name?.length ? 'error' : ''}
                                                        value={charter.name}
                                                        onChange={({target: {value}}) => setCharterWith('name', value)}
                                                    />
                                                ) : (
                                                    <strong>{charter.name}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <label>Charter Type</label>
                                                {mode.edit ? (
                                                    <Select
                                                        value={charter.charterType}
                                                        // status={!charter.charterType ? "error" : ""}
                                                        options={[{
                                                            value: 'single',
                                                            label: 'Day charter'
                                                        }, {
                                                            value: 'multi',
                                                            label: 'Multi-day charter'
                                                        }, {value: 'recurring', label: 'Recurring charter'}]}
                                                        onChange={(value) => setCharterWith('charterType', value)}
                                                    />
                                                ) : (
                                                    <strong>{charter.charterType === 'single' ? 'Day charter' : charter.charterType === 'multi' ? 'Multi-day charter' : charter.charterType === 'recurring' ? 'Recurring charter' : 'TBC'}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <label>Start Date</label>
                                                {mode.edit ? (
                                                    <DatePicker
                                                        placeholder="Start Date"
                                                        {...(!isEmpty(charter.startDate) ? {value: dayjs(charter?.startDate)} : {value: dayjs()})}
                                                        status={charter.charterType === 'single' & !charter.startDate ? 'error' : ''}
                                                        format={{
                                                            format: DATE_STRING,
                                                            type: 'mask',
                                                        }}
                                                        disabled={charter.charterType !== 'single'}
                                                        onChange={(value) => {
                                                            // setCharterWith('startDate', value?.format(DATE_STRING));
                                                            setCharterWith('startDate', value);
                                                        }}
                                                    />
                                                ) : (
                                                    <strong>
                                                        {charter.startDate ? dayjs(charter.startDate).format(DATE_STRING) : 'TBC'}
                                                    </strong>
                                                )}
                                            </Col>
                                            {/*<Col xs={24} lg={12}>*/}
                                            {/*    <label>Start time</label>*/}
                                            {/*    {mode.edit ? (*/}
                                            {/*        <TimeField*/}
                                            {/*            className="inputtext-small sb-time-edit"*/}
                                            {/*            value={*/}
                                            {/*                Number.isFinite(charter.startTime) && charter.startTime > 0*/}
                                            {/*                    ? secsSinceMidnightToDayjs(charter.startTime).format('HH:mm')*/}
                                            {/*                    : 0*/}
                                            {/*            }*/}
                                            {/*            status={!charter.startTime ? 'error' : ''}*/}
                                            {/*            onChange={(e, value) => {*/}
                                            {/*                value = dayjs(value, 'HH:mm');*/}
                                            {/*                setCharterWith('startTime', dayjsToSecsSinceMidnight(value));*/}
                                            {/*            }}*/}
                                            {/*        />*/}
                                            {/*    ) : (*/}
                                            {/*        <strong>*/}
                                            {/*            {charter.startTime*/}
                                            {/*                ? secsSinceMidnightToDayjs(charter.startTime).format('HH:mm')*/}
                                            {/*                : 'TBC'}*/}
                                            {/*        </strong>*/}
                                            {/*    )}*/}
                                            {/*</Col>*/}
                                            {/*<Col xs={24} lg={4}>*/}
                                            {/*    <label>Schedule</label>*/}
                                            {/*    {mode.edit ? <Select*/}
                                            {/*        className="w-100 mb-2"*/}
                                            {/*        placeholder="Operating calendars"*/}
                                            {/*        showSearch={true}*/}
                                            {/*        optionFilterProp="children"*/}
                                            {/*        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}*/}
                                            {/*        filterSort={(optionA, optionB) =>*/}
                                            {/*            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())*/}
                                            {/*        }*/}
                                            {/*        onChange={scheduleId => {*/}
                                            {/*            setCharterWith('scheduleId', scheduleId);*/}
                                            {/*        }}*/}
                                            {/*        value={charter.scheduleId}*/}
                                            {/*        allowClear*/}
                                            {/*        style={{*/}
                                            {/*            width: '100%',*/}
                                            {/*        }}*/}
                                            {/*        options={schedule_options(allSchedules)}*/}
                                            {/*    /> : (*/}
                                            {/*        <strong>*/}
                                            {/*            {charter.scheduleId ? allSchedules[charter.scheduleId].scheduleName : 'NA'}*/}
                                            {/*        </strong>*/}
                                            {/*    )}*/}
                                            {/*</Col>*/}
                                            <Col xs={24} lg={8}>
                                                <label>Customer</label>
                                                {mode.edit ? (
                                                    <>
                                                        <CustomerDetailsModal visible={newCustomerVisible}
                                                                              closeDisplay={() => setNewCustomerVisible(false)}
                                                                              onNewCustomer={customer => {
                                                                                  setCustomers(customers => ([
                                                                                      ...customers,
                                                                                      customer
                                                                                  ]));
                                                                                  setCharterWith(['customerId', 'customer'], [customer.customerId, customer]);
                                                                                  setNewCustomerVisible(false);
                                                                              }}/>
                                                        <Select style={{width: '100%'}} placeholder="Select a customer"
                                                                onChange={(value) => {
                                                                    const customer = customers.find(c => c.customerId === value);
                                                                    setCharterWith(['customerId', 'customer'], [value, customer]);
                                                                }}
                                                                value={charter.customerId}
                                                                options={customers.map((customer) => ({
                                                                    label: customer.name,
                                                                    value: customer.customerId,
                                                                }))}
                                                                dropdownRender={menu => (
                                                                    <div>
                                                                        {menu}
                                                                        <Divider style={{margin: '4px 0'}}/>
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            flexWrap: 'nowrap',
                                                                            padding: 8
                                                                        }}>
                                                                            <Button
                                                                                variant="outline-dark"
                                                                                style={{
                                                                                    color: '#1890ff',
                                                                                    flex: 'none',
                                                                                    padding: '8px',
                                                                                    display: 'block',
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                onClick={() => setNewCustomerVisible(true)}
                                                                            >
                                                                                <PlusOutlined/> Customer
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                )}/>
                                                    </>
                                                ) : (
                                                    <strong>{charter.customer?.name || 'NA'}</strong>
                                                )}
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <label>Passengers</label>
                                                {mode.edit ? (
                                                    <InputNumber
                                                        placeholder="Enter passenger count"
                                                        value={charter.passengerCount}
                                                        onChange={(value) => setCharterWith('passengerCount', value)}
                                                        className="w-100"
                                                    />
                                                ) : (
                                                    <strong>{charter.passengerCount}</strong>
                                                )}
                                            </Col>
                                            {/*<Col xs={24} lg={8}>*/}
                                            {/*    <label>Vehicle</label>*/}
                                            {/*    {mode.edit ? (*/}
                                            {/*        <Select*/}
                                            {/*            value={charter.vehicleId}*/}
                                            {/*            status={!charter.vehicleId ? 'error' : ''}*/}
                                            {/*            options={filteredVehicles.map(v => ({*/}
                                            {/*                label: v.vehicleName,*/}
                                            {/*                value: v.vehicleId*/}
                                            {/*            }))}*/}
                                            {/*            onChange={(value) => setCharterWith(['vehicleId', 'vehicle'], [value, filteredVehicles.find(v => v.vehicleId === value)])}*/}
                                            {/*        />*/}
                                            {/*    ) : (*/}
                                            {/*        <strong>{charter.vehicle?.vehicleName || 'TBC'}</strong>*/}
                                            {/*    )}*/}
                                            {/*</Col>*/}
                                            {/*<Col xs={24} lg={8}>*/}
                                            {/*    <label>Pay Type</label>*/}
                                            {/*    {mode.edit ? (*/}
                                            {/*        <Select*/}
                                            {/*            value={charter.payType}*/}
                                            {/*            // status={!charter.payType ? "error" : ""}*/}
                                            {/*            options={[*/}
                                            {/*                {value: 'Rate of the day'},*/}
                                            {/*                {value: 'Monthly retainer'},*/}
                                            {/*                {value: 'Fixed cost'},*/}
                                            {/*            ]}*/}
                                            {/*            onChange={(value) => setCharterWith('payType', value)}*/}
                                            {/*        />*/}
                                            {/*    ) : (*/}
                                            {/*        <strong>{charter.payType}</strong>*/}
                                            {/*    )}*/}
                                            {/*</Col>*/}
                                            <Col xs={24} lg={8}>
                                                <label>Status</label>
                                                {mode.edit ? (
                                                    <Select
                                                        value={charter.status}
                                                        status={!charter.status ? 'error' : ''}
                                                        options={CHARTER_STATUS}
                                                        onChange={(value) => setCharterWith('status', value)}
                                                        defaultValue={'new'}
                                                    />
                                                ) : (
                                                    <div className="simple-tags">
                                                        <FilterTags filterItems={filterItems} model={charter}/>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row gutter={[30, 30]} style={{marginTop: 30}}>
                                            <Col xs={24} lg={8}>
                                                <label>Additional Information</label>
                                                {mode.edit ? (
                                                    <TextArea
                                                        placeholder="Enter Additional Information"
                                                        value={charter.note}
                                                        onChange={({target: {value}}) => setCharterWith('note', value)}
                                                    />
                                                ) : (
                                                    <strong>{(charter.note || '').split('\n').map(p =>
                                                        <Paragraph>{p}</Paragraph>)}</strong>
                                                )}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                {/*<Col xs={12}>*/}
                                {/*    <Card*/}
                                {/*        title={*/}
                                {/*            <Flex justify="space-between">*/}
                                {/*                <div>Date & Time</div>*/}
                                {/*            </Flex>*/}
                                {/*        }*/}
                                {/*        bordered={false}*/}
                                {/*        className="card-main card-info"*/}
                                {/*    >*/}
                                {/*        <Row gutter={[30, 30]}>*/}
                                {/*            <Col xs={24} lg={12}>*/}
                                {/*                <label>Start Date</label>*/}
                                {/*                {mode.edit ? (*/}
                                {/*                    <DatePicker*/}
                                {/*                        placeholder="Start Date"*/}
                                {/*                        {...(!isEmpty(charter.startDate) ? {value: dayjs(charter.startDate)} : {value: dayjs()})}*/}
                                {/*                        status={!charter.startDate ? 'error' : ''}*/}
                                {/*                        format={{*/}
                                {/*                            format: DATE_STRING,*/}
                                {/*                            type: 'mask',*/}
                                {/*                        }}*/}
                                {/*                        onChange={(value) => {*/}
                                {/*                            setCharterWith('startDate', value?.format(DATE_STRING));*/}
                                {/*                        }}*/}
                                {/*                    />*/}
                                {/*                ) : (*/}
                                {/*                    <strong>*/}
                                {/*                        {charter.startDate ? dayjs(charter.startDate).format(DATE_STRING) : 'TBC'}*/}
                                {/*                    </strong>*/}
                                {/*                )}*/}
                                {/*            </Col>*/}

                                {/*            /!*<Col xs={24} lg={12}>*!/*/}
                                {/*            /!*    <label>End Date</label>*!/*/}
                                {/*            /!*    {mode.edit ? (*!/*/}
                                {/*            /!*        <DatePicker*!/*/}
                                {/*            /!*            placeholder="End Date"*!/*/}
                                {/*            /!*            {...(!isEmpty(charter.endDate) ? {value: dayjs(charter.endDate)} : !isEmpty(charter.startDate) ? {minDate: dayjs(charter.startDate)} : {})}*!/*/}
                                {/*            /!*            {...(!isEmpty(charter.startDate) ? {minDate: dayjs(charter.startDate)} : {})}*!/*/}
                                {/*            /!*            status={!charter.endDate ? 'error' : ''}*!/*/}
                                {/*            /!*            format={{*!/*/}
                                {/*            /!*                format: DATE_STRING,*!/*/}
                                {/*            /!*                type: 'mask',*!/*/}
                                {/*            /!*            }}*!/*/}
                                {/*            /!*            onChange={(value) => {*!/*/}
                                {/*            /!*                setCharterWith('endDate', value?.format(DATE_STRING));*!/*/}
                                {/*            /!*            }}*!/*/}
                                {/*            /!*        />*!/*/}
                                {/*            /!*    ) : (*!/*/}
                                {/*            /!*        <strong>{charter.endDate ? dayjs(charter.endDate).format(DATE_STRING) : 'TBC'}</strong>*!/*/}
                                {/*            /!*    )}*!/*/}
                                {/*            /!*</Col>*!/*/}
                                {/*            /!*<Col xs={24} lg={12}>*!/*/}
                                {/*            /!*    <label>End time</label>*!/*/}
                                {/*            /!*    {mode.edit ? (*!/*/}
                                {/*            /!*        <TimeField*!/*/}
                                {/*            /!*            className="inputtext-small sb-time-edit"*!/*/}
                                {/*            /!*            value={*!/*/}
                                {/*            /!*                Number.isFinite(charter.endTime) && charter.endTime > 0*!/*/}
                                {/*            /!*                    ? secsSinceMidnightToDayjs(charter.endTime).format('HH:mm')*!/*/}
                                {/*            /!*                    : 0*!/*/}
                                {/*            /!*            }*!/*/}
                                {/*            /!*            status={!charter.endTime ? 'error' : ''}*!/*/}
                                {/*            /!*            onChange={(e, value) => {*!/*/}
                                {/*            /!*                value = dayjs(value, 'HH:mm');*!/*/}
                                {/*            /!*                setCharterWith('endTime', dayjsToSecsSinceMidnight(value));*!/*/}
                                {/*            /!*            }}*!/*/}
                                {/*            /!*        />*!/*/}
                                {/*            /!*    ) : (*!/*/}
                                {/*            /!*        <strong>*!/*/}
                                {/*            /!*            {charter.endTime*!/*/}
                                {/*            /!*                ? secsSinceMidnightToDayjs(charter.endTime).format('HH:mm')*!/*/}
                                {/*            /!*                : 'TBC'}*!/*/}
                                {/*            /!*        </strong>*!/*/}
                                {/*            /!*    )}*!/*/}
                                {/*            /!*</Col>*!/*/}


                                {/*            /!*<Col xs={24} lg={12}>*!/*/}
                                {/*            /!*    <label>Round Trip</label>*!/*/}
                                {/*            /!*    <Checkbox disabled={true} checked={roundtrip}*!/*/}
                                {/*            /!*              onChange={(e) => setRoundtrip(e.target.checked)}/>*!/*/}
                                {/*            /!*</Col>*!/*/}
                                {/*            /!*<Col xs={24} lg={24}>*!/*/}
                                {/*            /!*    <Destinations destinations={charter.destinations}*!/*/}
                                {/*            /!*                  setDestinations={(destinations) => setCharterWith('destinations', destinations)}/>*!/*/}
                                {/*            /!*</Col>*!/*/}
                                {/*            /!*<Col xs={24} lg={12}>*!/*/}
                                {/*            /!*    <label>Recurring</label>*!/*/}
                                {/*            /!*    {mode.edit ? (*!/*/}
                                {/*            /!*        <Select*!/*/}
                                {/*            /!*            value={charter.recurring}*!/*/}
                                {/*            /!*            // status={!charter.recurring ? "error" : ""}*!/*/}
                                {/*            /!*            options={[{ value: "Yes" }, { value: "No" }]}*!/*/}
                                {/*            /!*            onChange={(value) => setCharterWith("recurring", value)}*!/*/}
                                {/*            /!*            defaultValue="No"*!/*/}
                                {/*            /!*        />*!/*/}
                                {/*            /!*    ) : (*!/*/}
                                {/*            /!*        <strong>{charter.recurring}</strong>*!/*/}
                                {/*            /!*    )}*!/*/}
                                {/*            /!*</Col>*!/*/}
                                {/*            /!*<Col xs={24} lg={12}>*!/*/}
                                {/*            /!*    <label>Driver Hours</label>*!/*/}
                                {/*            /!*    {mode.edit ? (*!/*/}
                                {/*            /!*        <InputNumber*!/*/}
                                {/*            /!*            placeholder="Enter Driver hours"*!/*/}
                                {/*            /!*            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*!/*/}
                                {/*            /!*            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}*!/*/}
                                {/*            /!*            // status={!charter.driverHours ? "error" : ""}*!/*/}
                                {/*            /!*            value={charter.driverHours}*!/*/}
                                {/*            /!*            onChange={(value) => setCharterWith("driverHours", value)}*!/*/}
                                {/*            /!*        />*!/*/}
                                {/*            /!*    ) : (*!/*/}
                                {/*            /!*        <strong>{startCase(charter.driverHours)}</strong>*!/*/}
                                {/*            /!*    )}*!/*/}
                                {/*            /!*</Col>*!/*/}
                                {/*        </Row>*/}
                                {/*    </Card>*/}
                                {/*</Col>*/}
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : (
                <LoadMessage message="Loading Charter Details..."/>
            )}
        </div>
    );
}

export default React.memo(CharterDetails);
