import {BusRoute, Trip} from "../model/busRoute";
import Pin from "./Pin";
import {
    getDepartureTimeAsDayjs,
} from "../libs/routes-lib";
import React, {useCallback, useEffect, useState} from "react";
import {useScroll} from "../libs/hooksLib";
import {Button, Tooltip} from "antd";
import {useAppContext} from "../libs/contextLib";
import config from "../config";
import {cloneDeep, debounce} from "lodash";
import RouteNumber from "./RouteNumber";
import {find} from "lodash/collection";
import {CommentOutlined, EyeOutlined} from "@ant-design/icons";
import {differenceBy} from "lodash/array";
import ChatButton from "./ChatButton";

export default function StopTimesTimetable({
                                               route,
                                               allStops,
                                               trip,
                                               setRoute,
                                               setFocusStop,
                                               setSelectedStop,
                                               selectedStop,
                                               setUpdated,
                                               immutable = false,
                                               operator,
                                               focusTransfers,
                                               setFocusTransfers,
                                           }) {
    const {isAdmin, setChatOpen, setChatContext} = useAppContext() || {};
    const [editing, setEditing] = useState(false);
    const [executeScroll, scrollRef] = useScroll();
    const [stopTimes, setStopTimes] = useState([]);

    useEffect(() => {
        executeScroll();
    }, [selectedStop, executeScroll]);

    useEffect(() => {
        if (!trip?.stopTimes) return
        console.log('trip updated... setting stopTimes', trip.stopTimes)
        setStopTimes(immutable ? trip.getVisiblePublicStopTimes(allStops) : trip.stopTimes)
    }, [trip, allStops, immutable]);

    const setTrip = useCallback((trip) => {
        setRoute(route => {
            route = route.clone()
            const tripIdx = route.services.findIndex(t => t.tripId === trip.tripId)
            route.services[tripIdx] = trip;
            route = new BusRoute({...route})
            route.setBaseStops(allStops, cloneDeep(trip.stopTimes));
            console.log(route.stops);
            return route;
        })
    }, [setRoute, allStops])

    // eslint-disable-next-line
    const debouncedTripUpdate = useCallback(
        debounce((trip) => {
            setTrip(new Trip({...trip, stopTimes}))
            // setSelectedStop({...stop})
            setUpdated(true)
            setEditing(false)
        }, 500),
        [setTrip, setUpdated, stopTimes, setEditing]
    )

    return (
        <div className="StopTimesTimetable mt-2">
            {/* <div className="row sb-row-heading align-items-center" key={`Stop-Header`}>
                <div className="col-sm-1"></div>
                <div className="col-sm-8">Stop Name</div>
                <div className="col-sm-3 text-center pl-0 pr-0">Departure Time</div>
            </div> */}
            {
                stopTimes && stopTimes.filter(st => !!allStops[st.stopId]).map((stop, idx) => {
                    const showSuburb = operator?.opts?.timetable?.showSuburbNextToStop
                    const suburb = showSuburb &&  allStops[stop.stopId]?.suburb ? `, ${allStops[stop.stopId]?.suburb}` : ''
                        return (
                            <div key={`StopTime-TRow-${stop.stopTimeId}}`}>
                                <div key={`StopTime-Row-${stop.stopTimeId}}`}
                                     style={{cursor: 'pointer'}}
                                     onMouseOver={() => {
                                         if (!editing) {
                                             setFocusStop({...stop, ...allStops[stop.stopId]});
                                         }
                                     }}
                                     onClick={() => {
                                         setSelectedStop({...stop, ...allStops[stop.stopId]});
                                     }}
                                     ref={selectedStop && stop.stopTimeId === selectedStop.stopTimeId ? scrollRef : undefined}
                                     className={`row stop-tt-row align-items-center ${stop.timingPoint || route.charter ? 'TP' : ''} ${selectedStop && stop.stopTimeId === selectedStop.stopTimeId ? 'Selected' : ''}`}>
                                    <div
                                        className="col-2 text-center pl-2 pr-0 d-flex align-items-center justify-content-center">
                                        {stop.timingPoint || route.charter || operator?.opts?.timetable?.stopTimes?.showAll ?
                                            <span className={"stop-tt-time"} style={{
                                                fontStyle: `${stop.timingPoint || route.charter ? '' : 'italic'}`,
                                                fontWeight: `${stop.timingPoint || route.charter ? 'bold' : ''}`
                                            }}>{getDepartureTimeAsDayjs(trip, stop)?.format('HH:mm') || '-:-'}</span> :
                                            <></>
                                        }
                                    </div>
                                    <div
                                        className="col-1 pl-0 pr-0 d-flex align-items-center justify-content-center">{!immutable ?
                                        <Pin size={25} type={allStops[stop.stopId]?.stopType}/> :
                                        <Pin size={20} sequence={trip.getStopSequence(stop, allStops)}
                                             type={allStops[stop.stopId]?.stopType}/>}</div>
                                    <div className="col-8 d-flex align-items-center">
                                        {isAdmin || config.local ?
                                            <Tooltip
                                                title={`StopTime ID: ${stop.stopTimeId}, Stop ID: ${stop.stopId}, Wp idx: ${stop.wpIdx}`}><span>{allStops[stop.stopId]?.stopName}{suburb}</span></Tooltip>
                                            :
                                            <span className="stop-tt-name">{allStops[stop.stopId]?.stopName}{suburb}</span>
                                        }
                                    </div>
                                    <div className={"col-1"}>
                                        {/*<ChatButton chatContext={{*/}
                                        {/*    type: 'route',*/}
                                        {/*    id: route.routeId,*/}
                                        {/*    routeId: route.routeId,*/}
                                        {/*    stopTimeId: stop.stopTimeId,*/}
                                        {/*    subTypeId: `${trip.tripId}_${stop.stopTimeId}`,*/}
                                        {/*    stopId: stop.stopId,*/}
                                        {/*    tripId: trip.tripId,*/}
                                        {/*    route,*/}
                                        {/*    stop: allStops[stop.stopId],*/}
                                        {/*    stopTime: stop,*/}
                                        {/*    subType: 'st'*/}
                                        {/*}}/>*/}
                                    </div>
                                </div>
                                {stop.transfersTo?.length > 0 &&
                                    <div
                                        className={`d-flex transfer-row-info align-items-center justify-content-center ${differenceBy(stop.transfersTo, focusTransfers.to, 'routeId')?.length ? '' : 'Selected'}`}
                                        onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setFocusTransfers(focusTransfers => {
                                                if (focusTransfers.to?.length) {
                                                    focusTransfers.to = []
                                                } else {
                                                    focusTransfers.to = stop.transfersTo
                                                }
                                                return {
                                                    ...focusTransfers,
                                                    stop: focusTransfers.from?.length || focusTransfers.to?.length ? {...stop, ...allStops[stop.stopId]} : null
                                                }
                                            })
                                        }}>
                                        <span className="show-transfers"><EyeOutlined/></span>
                                        <span>Transfer</span>
                                        {stop.transfersTo.map(tx => <div key={`TxRidTo${tx.transferId}`}
                                            className={`transfer-single ${tx.invalid ? "transfer-warning" : ""} ${focusTransfers.to.filter(t => t.routeId === tx.routeId).length ? 'Selected' : ''}`}
                                            onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setFocusTransfers(focusTransfers => {
                                                    if (find(focusTransfers.to, ['routeId', tx.routeId])) {
                                                        focusTransfers.to = focusTransfers.to.filter(t => t.routeId !== tx.routeId)
                                                    } else {
                                                        focusTransfers.to = focusTransfers.to.concat(tx)
                                                    }
                                                    return {
                                                        ...focusTransfers,
                                                        stop: focusTransfers.from?.length || focusTransfers.to?.length ? {...stop, ...allStops[stop.stopId]} : null
                                                    }
                                                })
                                            }}><RouteNumber key={'TxRidTo' + tx.routeId}
                                                            route={tx}

                                        /></div>)}
                                    </div>
                                }
                                {stop.transfersFrom?.length > 0 &&
                                    <div
                                        className={`d-flex transfer-row-info align-items-center justify-content-center ${differenceBy(stop.transfersFrom, focusTransfers.from, 'routeId')?.length ? '' : 'Selected'}`}
                                        onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setFocusTransfers(focusTransfers => {
                                                if (focusTransfers.from?.length) {
                                                    focusTransfers.from = []
                                                } else {
                                                    focusTransfers.from = stop.transfersFrom
                                                }
                                                return {
                                                    ...focusTransfers,
                                                    stop: focusTransfers.from?.length || focusTransfers.to?.length ? {...stop, ...allStops[stop.stopId]} : null
                                                }
                                            })
                                        }}>
                                        <span className="show-transfers"><EyeOutlined/></span>
                                        <span>Pickup</span>
                                        {stop.transfersFrom.map(tx => <div key={`TxRidFrom${tx.transferId}`}
                                            className={`transfer-single ${tx.invalid ? "transfer-warning" : ""} ${focusTransfers.from.filter(t => t.routeId === tx.routeId).length ? 'Selected' : ''}`}
                                            onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setFocusTransfers(focusTransfers => {
                                                    if (find(focusTransfers.from, ['routeId', tx.routeId])) {
                                                        focusTransfers.from = focusTransfers.from.filter(t => t.routeId !== tx.routeId)
                                                    } else {
                                                        focusTransfers.from = focusTransfers.from.concat(tx)
                                                    }
                                                    return {
                                                        ...focusTransfers,
                                                        stop: focusTransfers.from?.length || focusTransfers.to?.length ? {...stop, ...allStops[stop.stopId]} : null
                                                    }
                                                })
                                            }}><RouteNumber key={'TxRidFrom' + tx.routeId}
                                                            route={tx}

                                        /></div>)}
                                    </div>
                                }
                                <div className="row stop-tt-row stop-tt-line align-items-center">
                                    <div className="col-2"></div>
                                    <div className="col-1 d-flex align-items-center justify-content-center"></div>
                                    <div className="col-9"></div>
                                </div>
                            </div>
                        )
                    }
                )
            }
        </div>
    );
}
