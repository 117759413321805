import React, {useEffect, useMemo, useState} from 'react';
import {Input, Radio, Tag} from 'antd';
import {find, values} from 'lodash';
import {toHrsMinsSecs} from '../../libs/formatLib';
import LoadMessage from '../../components/LoadMessage';

const SelectVehicleView = ({scenario, roster, isDefault = false, selectedVehicle, setSelectedVehicle, allVehicles, onAvailabilityChange, workItem}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [availableVehicles, setAvailableVehicles] = useState([]);
    const [unavailableVehicles, setUnavailableVehicles] = useState([]);

    useEffect(() => {
        if (!scenario || !roster || !allVehicles) return;
        let availableVehicles = [], unavailableVehicles = [];
        values(allVehicles).forEach(vehicle => {

            const existingRoster = find(scenario.weeklyRosters, {defaultVehicleId: vehicle.vehicleId})
            if (isDefault && existingRoster && existingRoster.rosterId !== roster.rosterId) {
                unavailableVehicles.push(vehicle);
                return;
            } 
            if (scenario.isVehicleUnavailable(vehicle.vehicleId, workItem)) {
                unavailableVehicles.push({
                    ...vehicle,
                    vehicleStatus: 'Unavailable'
                });
                return
            } 
            
            if (vehicle.vehicleStatus !== 'Active') {
                unavailableVehicles.push(vehicle);
            } else {
                availableVehicles.push({
                    ...vehicle,
                    hours: toHrsMinsSecs(scenario.getTotalTime('vehicle', vehicle.vehicleId))
                });
            }
        });

        setAvailableVehicles(availableVehicles);
        setUnavailableVehicles(unavailableVehicles);
    }, [scenario, roster, isDefault, allVehicles, setAvailableVehicles, setUnavailableVehicles]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredVehicles = useMemo(() => {
        if (!searchTerm) return availableVehicles;
        const search = searchTerm.toLowerCase();
        return availableVehicles?.filter(vehicle => {
            return vehicle.vehicleRego?.toLowerCase().includes(search) ||
                vehicle.vehicleName?.toLowerCase().includes(search);
        }) || [];
    }, [availableVehicles, searchTerm]);

    useEffect(() => {
            // Check availability and pass true/false to parent
            onAvailabilityChange(unavailableVehicles.length > 0);
        }, [unavailableVehicles.length, onAvailabilityChange]);

    return (
        <>{allVehicles ? <>
                <div className={`item-allocation ${unavailableVehicles.length ? 'showVehicleUnavailable' : 'hideVehicleUnavailable'}`}>
                    <Input type="text" placeholder="Search vehicle" value={searchTerm} onChange={handleSearch}/>
                    <div className="item-list-scroll">
                        <div className="item-list">
                            {filteredVehicles?.length ? filteredVehicles.map(vehicle => (
                                <div key={'available-vehicle-' + vehicle.vehicleId} className="item-single" onClick={() => setSelectedVehicle(vehicle)}>
                                    <Radio name="vehicle"
                                        checked={selectedVehicle?.vehicleId === vehicle.vehicleId}>
                                        <span className="vehicle-name">{vehicle.vehicleName}</span>
                                    </Radio>
                                </div>
                            )) : <div className="no-results">No vehicles found</div>}
                        </div>
                    </div>
                    {unavailableVehicles.length ? <> <h6 className="mt-4 mb-0" style={{color: '#777', fontWeight: 600}}>Unavailable Vehicles</h6>
                    <div className="item-list-scroll">
                        <div className="item-list">
                            {unavailableVehicles.map(vehicle => (
                                <div key={'unavailable-vehicle-' + vehicle.vehicleId} className="item-unavailable">
                                    <span className="vehicle-name" style={{opacity: '0.7'}}>{vehicle.vehicleName}</span>
                                    <span className="staff-status simple-tags"><Tag
                                        color="error">{vehicle.vehicleStatus}</Tag></span>
                                </div>
                            ))}
                        </div>
                    </div>
                    </> : <></>}
                </div>
            </> :
            <LoadMessage title="Loading vehicles..."/>
        }
        </>
    );
};

export default React.memo(SelectVehicleView);