import React, {useState} from 'react';
import ShiftBatPrint from './ShiftBatPrint';
import PrintOptions from '../../components/PrintOptions';

function ShiftBatPrintView({allStops, allRoutes, operatorId, shiftBat, schedules, charter, customer, allocation}) {
    const [printLayout, setPrintLayout] = useState('lone'); // lone, ltwo
    const [printMode, setPrintMode] = useState('lg'); // lg, md, sm
    const [showMap, setShowMap] = useState(false);

    return (
        <>
            <PrintOptions printLayout={printLayout} setPrintLayout={setPrintLayout} printMode={printMode}
                          setPrintMode={setPrintMode} showMap={showMap} setShowMap={setShowMap}/>
            <ShiftBatPrint shiftBat={shiftBat} allStops={allStops} allRoutes={allRoutes} schedules={schedules}
                           printLayout={printLayout} printMode={printMode} showMap={showMap} operatorId={operatorId}
                           charter={charter} customer={customer} allocation={allocation}/>
        </>
    );
}

export default React.memo(ShiftBatPrintView);
