import React, { useEffect, useState } from "react";

const UpdateTitle = ({ targetClass, htmlContent, updateKey, maxRetries = 5 }) => {
    const [isAppended, setIsAppended] = useState(false);
    const [retryCount, setRetryCount] = useState(0);

    useEffect(() => {
        const tryUpdateTitle = () => {
            const element = document.querySelector(`.${targetClass}`);
            const updatedValue = document.querySelector(`.${updateKey}`);
            // if updateValue exists remove it
            if (updatedValue) {
                updatedValue.remove();
            }
            if (element) {
                const tempDiv = document.createElement("div");
                tempDiv.innerHTML = htmlContent;
                if (tempDiv.firstChild) {
                    element.appendChild(tempDiv.firstChild);
                    setIsAppended(true);
                }
            } else if (retryCount < maxRetries) {
                // Retry after a short delay if element is not found
                setRetryCount(retryCount + 1);
                setTimeout(tryUpdateTitle, 100);
            }
        };

        if (!isAppended) {
            tryUpdateTitle();
        }
    }, [isAppended, targetClass, htmlContent, updateKey, retryCount, maxRetries]);

    return null;
};

export default UpdateTitle;